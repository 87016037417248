/*
 * @Descripttion: 
 * @version: 
 * @Author: hutian
 * @Date: 2021-03-16 18:19:55
 * @LastEditors: hutian
 * @LastEditTime: 2021-03-17 17:41:47
 */
export const columns = [
{
    title: '大区',
    dataIndex: 'mhiacAreaName',
    key: 'mhiacAreaName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '事务所',
    dataIndex: 'firmName',
    key: 'firmName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '经销商名称',
    dataIndex: 'dealerName',
    key: 'dealerName',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '当前积分',
    dataIndex: 'integral',
    key: 'integral',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
{
    title: '已使用积分',
    dataIndex: 'consumptionIntegral',
    key: 'consumptionIntegral',
    /*width:150,*/
    align:'center',
    ellipsis:true
},
]


export const IntegralDetailColumns = [
    {
        title: '来源',
        dataIndex: 'source',
        key: 'source',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '时间',
        dataIndex: 'createTime',
        key: 'createTime',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '积分明细',
        dataIndex: 'dealIntegral',
        key: 'dealIntegral',
        /*width:150,*/
        align:'center',
        ellipsis:true,
        scopedSlots: { customRender: 'dealIntegral'}
    },
    {
        title: '积分余额',
        dataIndex: 'afterTheDealIntegral',
        key: 'afterTheDealIntegral',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    {
        title: '备注',
        dataIndex: 'remark',
        key: 'remark',
        /*width:150,*/
        align:'center',
        ellipsis:true
    },
    ]
